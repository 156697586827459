var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myActivityList"},[_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"activityItem",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('div',{staticClass:"item-l"},[_c('img',{attrs:{"src":item.activityPicture,"alt":""}})]),_c('div',{staticClass:"item-r"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.activityName)+" ")]),(
              item.scheduleList[0].pointsSettingId != '' &&
              item.activityStatus == 4 &&
              item.isCheckIn == 0
            )?_c('div',{staticClass:"btn",on:{"click":function($event){$event.stopPropagation();return _vm.sign(item)}}},[_vm._v(" 签到 ")]):(
              item.scheduleList[0].pointsSettingId != '' &&
              item.activityStatus == 4 &&
              item.isCheckIn == 1
            )?_c('div',{staticClass:"btn-ok"},[_vm._v(" 已签到 ")]):_vm._e()]),_c('p',{staticClass:"p-apply"},[_vm._v(" 报名开始时间："+_vm._s(item.scheduleList && item.scheduleList[0] ? item.scheduleList[0].applyStartDate : item.applyStartDate)+" ")]),_c('p',{staticClass:"p-activity"},[_vm._v(" 活动开始时间："+_vm._s(item.scheduleList && item.scheduleList[0] ? item.scheduleList[0].startDate : item.startDate)+" ")])]),_c('div',{staticClass:"status"})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }